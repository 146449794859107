import styled, { css } from 'styled-components'

import { tabletBreakpoint, desktopBreakpoint } from 'react-components'
import { CarouselProvider, Slider } from 'pure-react-carousel'

export const Container = styled.div`
    margin: 0 ${props => props.theme.spacing.xSmall} 0 0;
    cursor: pointer;
    border-radius: ${({ theme }) => theme.typography.border.radius.small};
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        margin: 0 ${props => props.theme.spacing.medium} 0 0;
    }
    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        margin: 0 ${props => props.theme.spacing.large} 0 0;
    }
`

export const InnerContainer = styled.div`
    background-color: ${props => props.theme.colors.white};
    position: ${props => props.theme.style.position.relative};
    min-height: 23.6875rem;
    transition: box-shadow 200ms ease-out;
    border-radius: ${props => props.theme.typography.border.radius.xLarge};
`

export const VerticalSeparator = styled.div`
    display: ${props => props.theme.style.display.flex};
    width: 0.0625rem;
    height: 1.5rem;
    margin: 0.6875rem ${props => props.theme.spacing.xSmall} ${props => props.theme.spacing.xxSmall};
    background: ${props => props.theme.colors.tfwBlackOpacity10};
`

export const Title = styled.h4`
    margin: 0;
    width: ${props => props.theme.layout.fill};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${props => props.theme.colors.tfwBlack87};
    font: ${props => props.theme.typography.font.weight.bold} ${props => props.theme.typography.font.size.large} /
        1.5rem ${props => props.theme.typography.font.family.latoVerdana};
`

type CarouselProps = {
    imageCarouselBorderRadius?: string
    horizontalCardHeight?: string
    margin?: string
}

export const CarouselContainer = styled.div<CarouselProps>(
    ({ theme, imageCarouselBorderRadius, horizontalCardHeight, margin }) => css`
        height: ${!!horizontalCardHeight ? horizontalCardHeight : '100%'};
        position: ${theme.style.position.relative};
        border-radius: ${!!imageCarouselBorderRadius ? imageCarouselBorderRadius : `0.375rem 0.375rem 0 0`};

        @media only screen and (min-width: ${tabletBreakpoint.min}px) {
            margin-right: ${theme.spacing.medium};
        }
        @media only screen and (min-width: ${desktopBreakpoint.min}px) {
            margin-right: 1.125rem;
        }
        ${!!margin && `margin: ${margin} !important`};
    `,
)

export const StyledCarouselProvider = styled(CarouselProvider)`
    width: 100%;
    li {
        list-style: none;
    }
`

export const StyledSlider = styled(Slider)`
    padding: 0;
    ul {
        transition: transform 0.5s;
        transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
        will-change: transform;
    }

    & > .carousel__slider-tray-wrapper {
        overflow: hidden;
    }
`

export const CarouselImage = styled.div<CarouselProps>(
    ({ theme, horizontalCardHeight }) => css`
        height: ${!!horizontalCardHeight ? horizontalCardHeight : '100%'};
        width: ${theme.layout.fill};
        position: ${theme.style.position.relative};
        overflow: hidden;
        border-radius: ${theme.typography.border.radius.xLarge} ${theme.typography.border.radius.xLarge} 0 0;
        @media only screen and (min-width: ${tabletBreakpoint.min}px) {
            border-radius: ${theme.typography.border.radius.xLarge};
        }
        border-top-left-radius: ${theme.typography.border.radius.xLarge};
        border-top-right-radius: ${theme.typography.border.radius.xLarge};
    `,
)
