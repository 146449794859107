import React, { FC, memo, Fragment } from 'react'

import { useCurrentSlideIndex } from '../../../../hooks/carousel'
import { CarouselIcon, StyledCarouselNextButton } from './image-carousel-styles'
import { ChevronRightIcon } from '../../../common/icons'
import { AnyStyledComponent } from 'styled-components'

interface Props {
    numberOfSlides: number
}

const CarouselButtonNext: FC<Props> = ({ numberOfSlides }) => {
    const _currentSlideIndex = useCurrentSlideIndex()

    return _currentSlideIndex !== numberOfSlides - 1 ? (
        <StyledCarouselNextButton $right>
            <CarouselIcon as={ChevronRightIcon as AnyStyledComponent} />
        </StyledCarouselNextButton>
    ) : (
        <Fragment />
    )
}

export default memo(CarouselButtonNext)
