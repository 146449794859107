import React, { FC, useCallback, useMemo } from 'react'

import ImageTransport from '../../../transports/common/image-transport'
import ImageLoadingComponent from '../../app-components/image-loading-component'
import { CarouselContainer, CarouselImage, StyledSlider } from './image-carousel-styles'
import { DotsContainer } from '../../landing-page/common/property-card/dots-container'
import CarouselButtonNext from '../../landing-page/common/property-card/carousel-button-next'
import CarouselButtonBack from '../../landing-page/common/property-card/carousel-button-back'
import { StyledCarouselProvider } from '../../landing-page/common/property-card/image-carousel-styles'

type props = {
    images: ImageTransport[]
    imageCarouselBorderRadius?: string
    aspectRatio?: string
    horizontalCardHeight?: string
    imageCarouselMargin?: string
}

const getRegionImages = (regionImages: ImageTransport[], aspectRatio?: string, horizontalCardHeight?: string) => {
    return regionImages.map(image => (
        <CarouselImage key={image.url} horizontalCardHeight={horizontalCardHeight}>
            <ImageLoadingComponent src={image.url} alt={image.alt} paddingTop={aspectRatio} />
        </CarouselImage>
    ))
}

const ImageCarousel: FC<props> = ({
    images,
    imageCarouselBorderRadius,
    aspectRatio,
    horizontalCardHeight,
    imageCarouselMargin,
}) => {
    const regionImages = useMemo(
        () => getRegionImages(images, aspectRatio, horizontalCardHeight),
        [images, aspectRatio, horizontalCardHeight],
    )

    const handleImageContainerClick = useCallback(e => {
        e.stopPropagation()
        e.preventDefault()
    }, [])

    return (
        <CarouselContainer
            horizontalCardHeight={horizontalCardHeight}
            imageCarouselBorderRadius={imageCarouselBorderRadius}
            margin={imageCarouselMargin}
            onClick={handleImageContainerClick}
        >
            <StyledCarouselProvider
                totalSlides={regionImages.length}
                visibleSlides={1}
                naturalSlideWidth={1}
                naturalSlideHeight={1}
                isIntrinsicHeight
                lockOnWindowScroll
                dragStep={1}
                dragEnabled={regionImages.length > 1}
                step={1}
            >
                <CarouselButtonBack />
                <StyledSlider>{regionImages}</StyledSlider>
                {regionImages.length > 1 && (
                    <DotsContainer borderRadius={'0.75rem'} numberOfDots={regionImages.length} />
                )}
                <CarouselButtonNext numberOfSlides={regionImages.length} />
            </StyledCarouselProvider>
        </CarouselContainer>
    )
}

export default ImageCarousel
