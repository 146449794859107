import React, { FC, memo, Fragment } from 'react'

import { useCurrentSlideIndex } from '../../../../hooks/carousel'
import { ChevronLeftIcon } from '../../../common/icons'
import { AnyStyledComponent } from 'styled-components'
import { CarouselIcon, StyledCarouselBackButton } from './image-carousel-styles'

const CarouselButtonBack: FC<{}> = () => {
    const _currentSlideIndex = useCurrentSlideIndex()

    return _currentSlideIndex !== 0 ? (
        <StyledCarouselBackButton $left>
            <CarouselIcon as={ChevronLeftIcon as AnyStyledComponent} />
        </StyledCarouselBackButton>
    ) : (
        <Fragment />
    )
}

export default memo(CarouselButtonBack)
