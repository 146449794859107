import React, { FC, useMemo, memo } from 'react'

import {
    DotsOuterWrapper,
    FixedContainer,
    StyledDot,
    StyledDotsContainer,
    StyledDotWrapper,
} from './image-carousel-styles'
import { getTranslationArray } from '../../utils'
import { useCurrentSlideIndex } from '../../../../hooks/carousel'

interface Props {
    numberOfDots: number
    borderRadius?: string
}

const renderDots = (_highlightedDotIndex: number, _numberOfDots: number) => {
    const arr = []
    for (let i = 0; i < _numberOfDots; i++) {
        arr.push(
            <StyledDotWrapper slide={i} key={i}>
                <StyledDot selected={_highlightedDotIndex === i} />
            </StyledDotWrapper>,
        )
    }
    return arr
}

export const DotsContainer: FC<Props> = ({ numberOfDots, borderRadius }) => {
    const _highlightedDotIndex = Math.ceil(useCurrentSlideIndex())
    const _translationArray = useMemo(() => getTranslationArray(numberOfDots), [numberOfDots])
    const dots = useMemo(() => renderDots(_highlightedDotIndex, numberOfDots), [_highlightedDotIndex, numberOfDots])
    const offset = _translationArray[_highlightedDotIndex]

    return (
        <StyledDotsContainer borderRadius={borderRadius}>
            <FixedContainer {...(numberOfDots < 5 && { justifyContent: 'center' })}>
                <DotsOuterWrapper offset={`-${offset}px`}>{dots}</DotsOuterWrapper>
            </FixedContainer>
        </StyledDotsContainer>
    )
}

export default memo(DotsContainer)
