import React, { memo, useState } from 'react'
import { ImageContainer, LoadingContainer, Container } from './image-component-styles'
import { getBasePath } from '../../../utils/miscellaneous-utils'

type Props = {
    src?: string
    alt?: string
    objectFit?: string
    borderRadius?: string
    paddingTop?: string
}

const ImageLoadingComponent: React.FC<Props> = ({ src, alt, objectFit, borderRadius, paddingTop }) => {
    const [isLoading, setIsLoading] = useState(false)
    return (
        <Container paddingTop={paddingTop}>
            {isLoading && (
                <LoadingContainer
                    borderRadius={borderRadius}
                    src={getBasePath('/assets/images/loader/loader-grey.gif')}
                    alt={'loader'}
                    marginTop={paddingTop}
                />
            )}
            <ImageContainer
                borderRadius={borderRadius}
                objectFit={objectFit}
                src={src || ''}
                onLoadStart={() => setIsLoading(true)}
                onLoad={() => setIsLoading(false)}
                onError={() => setIsLoading(false)}
                alt={alt}
                loading="lazy"
            />
        </Container>
    )
}

export default memo(ImageLoadingComponent)
