import { NetworkService } from '../services'
import { BaseTransport } from '../transports'

class TrackingClient {
    public trackFilterRegionNode(regionNodeId: string, body: any): Promise<BaseTransport<void>> {
        return NetworkService.post({
            url: `/api/tracking/v1/filters/${regionNodeId}`,
            body: { filtersTracking: body },
        })
    }

    public trackRegionLandingPageListings(body: any): Promise<BaseTransport<void>> {
        return NetworkService.post({
            url: `/api/tracking/v1/region-landing-page`,
            body: { trackingEvents: body },
        })
    }
}

export default new TrackingClient()
