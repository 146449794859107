import styled, { css } from 'styled-components'
import { ButtonBack, ButtonNext, CarouselProvider, Dot, Slider } from 'pure-react-carousel'
import { desktopBreakpoint } from 'react-components'

export const CarouselContainer = styled.div<{ borderRadius?: string }>(
    ({ theme, borderRadius }) => css`
        position: relative;
        border: 0.375rem;
        border-radius: ${!!borderRadius
            ? borderRadius
            : `${theme.typography.border.radius.xLarge} ${theme.typography.border.radius.xLarge} 0 0`};
        overflow: hidden;
        z-index: 1;
    `,
)

export const CarouselImage = styled.div<{ height?: string }>(
    ({ theme, height }) => css`
        height: ${!!height ? height : theme.layout.fill};
        min-width: ${theme.layout.fill};
        position: ${theme.style.position.relative};
        overflow: hidden;
    `,
)

export const ImageContainer = styled.div`
    display: block;
`

export const StyledSlider = styled(Slider)<{ $transitionTime?: number }>(
    ({ $transitionTime }) => css`
        padding: 0;
        ul {
            transition: transform ${$transitionTime ? `${$transitionTime}s` : `0.5s`};
            transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
            will-change: transform;
        }
    `,
)

export const CarouselIcon = styled.img`
    height: 1rem;
    width: 1rem;
`

export const StyledCarouselBackButton = styled(ButtonBack)<{ $left?: boolean; $right?: boolean }>(
    ({ $left, $right, theme }) => css`
        position: ${theme.style.position.absolute};
        top: 42%;
        left: ${$left ? 0 : 'unset'};
        right: ${$right ? 0 : 'unset'};
        background-color: ${theme.colors.white};
        border-radius: 100%;
        margin: 0 1.25rem;
        height: ${theme.layout.xSmall};
        width: ${theme.layout.xSmall};
        display: flex;
        flex-direction: ${theme.style.flexDirection.column};
        align-items: ${theme.style.alignItems.center};
        justify-content: ${theme.style.justifyContent.center};
        opacity: 1;
        z-index: 10;
        @media only screen and (min-width: ${desktopBreakpoint.min}px) {
            opacity: 0;
        }

        &:hover {
            opacity: 1 !important;
            transition: opacity 200ms ease-in;
        }
    `,
)

export const StyledCarouselNextButton = styled(StyledCarouselBackButton).attrs({ as: ButtonNext })``

export const StyledCarouselProvider = styled(CarouselProvider)`
    width: ${props => props.theme.layout.fill};
    li {
        list-style: none;
    }
    &:hover ${StyledCarouselBackButton} {
        opacity: 0.8;
        transition: opacity 200ms ease-in;
    }
`

export const StyledDotsContainer = styled.div<{ borderRadius?: string }>(
    ({ theme, borderRadius }) => css`
        position: absolute;
        height: 8.4375rem;
        justify-content: center;
        align-items: flex-end;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 69.53%, rgba(0, 0, 0, 0.64) 100%);
        width: ${theme.layout.fill};
        bottom: 0;
        display: flex;
        z-index: 1;
        border-radius: ${!!borderRadius ? borderRadius : 'unset'};
    `,
)

export const FixedContainer = styled.div<{ justifyContent?: string }>(
    ({ justifyContent }) => css`
        margin-bottom: ${props => props.theme.spacing.small};
        align-items: center;
        display: flex;
        width: 50px;
        overflow: hidden;
        justify-content: ${justifyContent ? justifyContent : 'flex-start'};
    `,
)

export const DotsOuterWrapper = styled.div<{ offset: string }>(
    ({ offset }) => css`
        display: flex;
        transform: translateX(${offset});
    `,
)

export const StyledDotWrapper = styled(Dot)``

export const StyledDot = styled.div<{ selected: boolean }>(
    ({ selected }) => css`
        height: 0.375rem;
        width: 0.375rem;
        background: #ffffff80;
        border-radius: 50%;
        margin-right: 0.25rem;
        ${selected
            ? `
            height: 0.5rem;
            width: 0.5rem;
            background: white;
        `
            : ''}
    `,
)
